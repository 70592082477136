#hero {
  background-color: #ff5010;
  --pdx-min-height: 0px;
  --wr-max: 1200px;

  #headerSpacer {
    height: 70px;
  }

  & > .ft {
    --pdx-pt: 0;
    --pdx-pb: -100;

    .ft__wrapper {
      max-width: 570px;
    }

  }

  .pdx {
    --pdx-maxheight: 1;
  }

  a {
    box-shadow: 0 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12);
    text-decoration: none;
    border-radius: 4px;
    color: red;

    & + a {
      margin-top: 1.2rem;
    }

  }

}
