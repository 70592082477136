#header {
  --wr-max: 1200px;

  & > .wr {
    margin-top: 10px;
    margin-bottom: 10px
  }

  #headerLogo {
    font-weight: 400;
    font-size: 26px
  }

  .header__navCtas {

    a {
      color: #493b46;
    }

  }

  #headerLogo a {
    justify-content: center;
    align-items: center;
    display: flex;

    img {
      height: 3.2rem;
      width: auto;
    }

  }

  .headerMenu__wrapper {
    background-color:rgb(166, 0, 0);
  }

  .social-icon {
    justify-content: center;
    margin-right: 1.2rem;
    align-items: center;
    transition: all .3s;
    display: flex;

    &:hover {
      opacity: .8;
    }

    svg {
      height: 1.6rem;
      width: auto;
    }

  }

  #headerNav {

    .header__navCtas .btn {
      margin: 0;

      & + .btn {
        margin-left: 1.2rem;
      }

    }

  }

}
