#feature {
  background-color: rgb(2, 2, 2);
  --pdx-min-height: 0px;
  --wr-max: 1200px;

  & > .ft {
    --pdx-pt: 0;
    --pdx-pb: 0;

    & > .pdx {
      --pdx-maxheight: 1;
    }

    & > .ft__half {

      & > .ft__wrapper {
        max-width: 570px;
      }

    }

  }

}
