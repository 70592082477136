#footer {
  background-color: #2c252a;
  padding-bottom: 15px;
  padding-top: 15px;
  --pdx-min-height: 0px;
  --wr-max: 1200px;

  li a {
    display: block;

    img {
      width: auto;
      height: 1.4rem;
    }

  }

}
