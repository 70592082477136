#roadmap {
  background-color: rgb(0, 0, 0);
  padding-bottom: 60px;
  padding-top: 60px;
  --pdx-min-height: 0px;
  --wr-max: 1200px;

  .zigzagItem.completed {

    .zigzagItem__title,
    .zigzagItem__text {
      text-decoration: line-through;
      opacity: .5;
    }

  }

}
